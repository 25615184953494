@import "colors";

.contact {
    width: 100%;
    height: 100vh;
    background-color: $second;
    display: flex;
    justify-content: center;
    align-items: center;

    >main {
        background-color: $first;
        height: 40rem;
        width: 70%;
        padding: 3rem;
        border-radius: 0 150px 140px 0;

        
        >h1 {
            font-size: 60px;
            display: flex;
            //justify-content: center;
            justify-content: center;
            
        }

        >form {
            margin: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
             
            > div {
                height: 3rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 60%;
                margin: 0.5rem;

                > label{

                    font: 400 1.2rem $text1;
                    width: 20%;
                    margin: 0 1rem ;
                }

                > input{
                    align-self: stretch;
                    border-radius: 2px;
                    width: 80%;
                    padding: 1rem;
                    border: 1px solid rgba(25,71,126,0.6);
                    outline: none;
                    
                }
                
                > button{
                    
                    margin: 2rem ;
                    padding: 0.7rem;
                    align-items: bottom;
                    border: none;
                    border-radius: 8px;
                    background-color: $third;
                    color: $first;
                    width: 200px;
                    font: 500 1rem $text1;
    
                    &:hover{
                    background-color: $second;
                    opacity: 0.8;
                    box-shadow: 7px 7px 5px 0 rgba(0,0,0, 0.5 ) ;
                    }
                    
                
                }
                
                
            }


        }
        
    }
}